import React from "react"
import TruckIcon from "../assets/icons/track.svg"
import BoxIcon from "../assets/icons/box.svg"
import FreeDeliveryIcon from "../assets/icons/free-delivery.svg"
import PackingIcon from "../assets/icons/packing.svg"
import ToolsIcon from "../assets/icons/tools.svg"
import WrapIcon from "../assets/icons/wrap.svg"
import DestinationIcon from "../assets/icons/destination.svg"
import FastTimeIcon from "../assets/icons/fast-time.svg"
import MovingTruckIcon from "../assets/icons/moving-truck.svg"
import NoParkingIcon from "../assets/icons/no-parking.svg"
import PhoneIcon from "../assets/icons/phone.svg"
import CleaningIcon from "../assets/icons/cleaning.svg"
import PlugIcon from "../assets/icons/plug.svg"
import KitchenIcon from "../assets/icons/kitchen.svg"
import MovingPeopleIcon from "../assets/icons/moving-people.svg"

export const icons = {
  truck: <TruckIcon />,
  box: <BoxIcon />,
  "free-delivery": <FreeDeliveryIcon />,
  packing: <PackingIcon />,
  tools: <ToolsIcon />,
  wrap: <WrapIcon />,
  destination: <DestinationIcon />,
  "fast-time": <FastTimeIcon />,
  "moving-truck": <MovingTruckIcon />,
  "no-parking": <NoParkingIcon />,
  phone: <PhoneIcon />,
  cleaning: <CleaningIcon />,
  plug: <PlugIcon />,
  kitchen: <KitchenIcon />,
  "moving-people": <MovingPeopleIcon />,
}
